import { computed } from 'vue';

import type { FactoryId, FormStepsFactory, Step } from '@/types/formStepsFactory';

export const useStepsLpMortgage = () => {
  const factoryId: FactoryId = 'unsupported';

  const getFormStepsLpMortgage = (): FormStepsFactory => computed<Step[]>(() => []);

  return { factoryId, getFormStepsLpMortgage };
};

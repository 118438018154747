<template>
  <FInput
    v-model="modelValue.idNumber"
    clearable
    :error-message="errors.idNumber?.[0]"
    :error="!!errors.idNumber?.[0]"
    :label="t('LPOfferAdditional.fields.companyNumber.label')"
    :rules="[requiredDefault]"
    :required="true"
    outlined
  />

  <FInputFile
    v-model="modelValue.proofOfIdentityDocument"
    clearable
    :display-value="modelValue.proofOfIdentityDocument?.name || 'Select File...'"
    :error-message="errors.proofOfIdentityDocument?.[0]"
    :error="!!errors.proofOfIdentityDocument?.[0]"
    :label="t('LPOfferAdditional.fields.companyCertificate.label')"
    :rules="[requiredDefault]"
    :required="true"
    outlined
  >
    <template #prepend>
      <q-icon color="secondary" name="attachment" />
    </template>
  </FInputFile>

  <FInputToggle
    v-model="modelValue.hasPriorRelationship"
    :error="!!errors.hasPriorRelationship?.[0]"
    :error-message="errors.hasPriorRelationship?.[0]"
    :label="t('LPOfferAdditional.fields.hasPriorRelationship.label')"
    :rules="[requiredHasPriorRelationship]"
    :required="true"
  />

  <FInput
    v-if="modelValue.hasPriorRelationship === 'Yes'"
    v-model="modelValue.relationshipDescription"
    :error="!!errors.relationshipDescription?.[0]"
    :error-message="errors.relationshipDescription?.[0]"
    :label="t('LPOfferAdditional.fields.relationshipDescription.label')"
    :rules="[requiredDefault]"
    :required="true"
    outlined
    type="textarea"
  />

  <FInputToggle
    v-model="modelValue.hasArrearsOrRestructuredCreditFacilities"
    :error="!!errors.hasArrearsOrRestructuredCreditFacilities?.[0]"
    :error-message="errors.hasArrearsOrRestructuredCreditFacilities?.[0]"
    :label="t('LPOfferAdditional.fields.hasArrearsOrRestructuredCreditFacilities.label')"
    :rules="[requiredHasArrearsOrRestructuredCreditFacilities]"
    :required="true"
  />
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import FInput from '@/components/Form/input/FInput.vue';
import FInputFile from '@/components/Form/input/FInputFile.vue';
import FInputToggle from '@/components/Form/input/FInputToggle.vue';
import { useFormInputRules } from '@/composables/formInputRules';
import tFormSteps from '@/i18n/translations/components/formSteps.json';
import type { FNextOffer } from '@/types/formStepsFactory';

type FormDataAdditional = {
  idNumber?: null | string;
  proofOfIdentityDocument?: File | null;
  hasPriorRelationship?: 'Yes' | 'No' | null;
  relationshipDescription?: string | null;
  hasArrearsOrRestructuredCreditFacilities?: 'Yes' | 'No' | null;
};

type FormData = Record<'formData', FNextOffer['formData']> & FormDataAdditional;

defineProps<{
  errors: Partial<Record<keyof FormData | string, string[]>>;
}>();

const { t } = useI18n(tFormSteps);
const { required } = useFormInputRules();

const modelValue = defineModel<Partial<FormData>>('modelValue', { required: true });

const requiredDefault = required();

const requiredHasArrearsOrRestructuredCreditFacilities = required(
  t('LPOfferAdditional.fields.hasArrearsOrRestructuredCreditFacilities.rules.required')
);

const requiredHasPriorRelationship = required(
  t('LPOfferAdditional.fields.hasPriorRelationship.rules.required')
);
</script>

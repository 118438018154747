<template>
  <q-form :id="btnPropsSubmit.form" ref="elQForm" class="f-form" greedy @submit.prevent="onSubmit">
    <LPFOAdditionalAgent
      v-if="next.formData.submitterType === 'Agent'"
      v-model="formData"
      :errors="errors"
    />

    <LPFOAdditionalCompany
      v-else-if="next.formData.submitterType === 'Company'"
      v-model="formData"
      :errors="errors"
    />

    <LPFOAdditionalIndividual
      v-else-if="next.formData.submitterType === 'Individual'"
      v-model="formData"
      :errors="errors"
    />

    <div
      class="text-caption text-accent q-pt-md q-mb-md q-pb-md"
      v-text="t('LPOfferAdditional.extra.tenderAgreementSupport')"
    />

    <Teleport defer :disabled="!isDialog" :to="teleportTarget">
      <q-btn v-bind="btnPropsCancel" @click="onPrev" />
      <q-btn v-bind="btnPropsSubmit" />
    </Teleport>
  </q-form>
</template>

<script setup lang="ts">
import { computed, nextTick, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import LPFOAdditionalAgent from '@/components/ListingPage/Form/fragments/LPFormOfferAdditional/LPFOAdditionalAgent.vue';
import LPFOAdditionalCompany from '@/components/ListingPage/Form/fragments/LPFormOfferAdditional/LPFOAdditionalCompany.vue';
import LPFOAdditionalIndividual from '@/components/ListingPage/Form/fragments/LPFormOfferAdditional/LPFOAdditionalIndividual.vue';
import { useApiListing } from '@/composables/api/listing';
import { useFormInputRules } from '@/composables/formInputRules';
import { useCaptcha } from '@/composables/useCaptcha';
import tFormSteps from '@/i18n/translations/components/formSteps.json';
import type { ApiError } from '@/types/api';
import type { FNextOffer, FormId } from '@/types/formStepsFactory';

const { t } = useI18n(tFormSteps);

type FormDataAdditional = Record<string, boolean | number | string | undefined | Blob>;
type FormData = Record<'formData', FNextOffer['formData']> & FormDataAdditional;

const props = defineProps<{
  formId: FormId;
  isDialog?: boolean;
  next: FNextOffer & { formData: FormData };
}>();

const emit = defineEmits<{
  (e: 'next'): void;
  (e: 'prev'): void;
}>();

const { storeOffer } = useApiListing();
const { getToken } = useCaptcha();

const teleportTarget = computed(() => `#${props.formId} .dialog-form--actions`);

const errors = ref<Partial<Record<keyof typeof formDataMerged.value, string[]>>>({});
const loading = ref(false);

const { elQForm } = useFormInputRules();

const formData = ref<Partial<FormData>>({
  ...props.next.formData,
});

const btnPropsCancel = computed(() => ({
  class: 'text-body2-bold border-radius-xl',
  color: 'primary',
  label: t('LPOfferAdditional.btn.back'),
  noCaps: true,
  outline: true,
  padding: '0.8rem',
  unelevated: true,
}));

const btnPropsSubmit = computed(() => ({
  class: 'text-body2-bold border-radius-xl',
  color: 'primary',
  form: `f-${props.formId}`,
  label: t('LPOfferAdditional.btn.submit'),
  loading: loading.value,
  noCaps: true,
  padding: '0.8rem',
  textColor: 'white',
  type: 'submit',
  unelevated: true,
}));

const formDataMerged = computed(() => ({
  ...props.next.formData,
  ...formData.value,
}));

const onSubmit = async () => {
  errors.value = {};

  const v = await elQForm.value?.validate();
  if (!v) return;

  const rToken = await getToken('submitOffer');

  formData.value.r_token = rToken;

  loading.value = true;

  const data = new FormData();

  Object.keys(formDataMerged.value).forEach(key => {
    const valueTmp = formDataMerged.value[key];

    const value = valueTmp instanceof Blob ? valueTmp : valueTmp?.toString() || '';

    if (value) {
      data.append(key, value);
    }
  });

  storeOffer(data)
    .then(() => {
      emit('next');
    })
    .catch((error: ApiError<keyof typeof formData.value>) => {
      if ('response' in error && error.response) {
        const { response } = error;

        switch (response.status) {
          case 403:
            emit('prev');
            break;
          case 422:
            errors.value = response.data.errors;
            break;
          default:
            break;
        }
      } else {
        // TODO: handle network error
      }
    })
    .finally(() => {
      loading.value = false;
    });
};

const onPrev = () => {
  emit('prev');
};

watch(
  () => formData.value.customerType,
  v => {
    formData.value = { customerType: v };
    nextTick(() => {
      elQForm.value?.resetValidation();
    });
  }
);
</script>

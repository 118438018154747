import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import FormStepperCallback from '@/components/Form/FormStepperCallback.vue';
import LPFormOffer from '@/components/ListingPage/Form/LPFormOffer.vue';
import LPFormOfferAdditional from '@/components/ListingPage/Form/LPFormOfferAdditional.vue';
import LPFormOfferTerms from '@/components/ListingPage/Form/LPFormOfferTerms.vue';
import tFormStepsFactory from '@/i18n/translations/components/formStepsFactory.json';
import type { FactoryId, FormStepsFactory, Step } from '@/types/formStepsFactory';

import { useStepsAuth } from './auth';

export const useStepsLpOffer = () => {
  const factoryId: FactoryId = 'lp-offer';

  const { t } = useI18n({ ...tFormStepsFactory, useScope: 'global' });

  const getFormStepsLpOffer = (): FormStepsFactory => {
    const { getFormStepsAuth } = useStepsAuth();
    const authFormSteps = getFormStepsAuth();

    return computed<Step[]>(() => {
      const authFormStepsLocal = [...authFormSteps.value];

      authFormStepsLocal[0].subtitle = t('subtitle.offerLogin');
      authFormStepsLocal[0].title = t('title.offerLogin');

      return [
        {
          component: () => LPFormOffer,
          formId: 'lp-offer',
          subtitle: t('subtitle.offer'),
          title: t('title.offer'),
        },
        ...authFormStepsLocal,
        {
          component: () => LPFormOfferTerms,
          formId: 'lp-offer-terms',
          title: t('title.offerTerms'),
        },
        {
          component: () => LPFormOfferAdditional,
          formId: 'lp-offer-additional',
          subtitle: t('subtitle.offerAdditional'),
          title: t('title.offerAdditional'),
        },
        {
          component: () => FormStepperCallback,
          componentProps: {
            formInfoMsgPropsError: {
              qIconProps: {
                color: 'negative',
                name: 'warning',
                size: '5rem',
              },
              subtitle: { text: 'Error subtitle' },
              title: { text: 'Error title' },
            },
            formInfoMsgPropsSuccess: {
              qIconProps: {
                color: 'primary',
                name: 'success',
                size: '5rem',
              },
              subtitle: { text: t('subtitle.offerSuccess') },
              title: { text: t('title.offerSuccess') },
            },
          },
          formId: 'callback',
        },
      ];
    });
  };

  return { factoryId, getFormStepsLpOffer };
};

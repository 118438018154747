import { storeToRefs } from 'pinia';
import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import FormStepperCallback from '@/components/Form/FormStepperCallback.vue';
import LPFormOpenDay from '@/components/ListingPage/Form/LPFormOpenDay.vue';
import { useApiListing } from '@/composables/api/listing';
import { useCaptcha } from '@/composables/useCaptcha';
import tFormStepsFactory from '@/i18n/translations/components/formStepsFactory.json';
import useAuthStore from '@/store/modules/auth';
import type { StoreOpenDayVariables } from '@/types/api/listing';
import type { FactoryId, FormStepsFactory, Next, Step } from '@/types/formStepsFactory';

export const useStepsLpOpenDay = () => {
  const factoryId: FactoryId = 'lp-open-day';

  const { authMeta, dialogAuth, dialogFactoryId, user } = storeToRefs(useAuthStore());
  const { t } = useI18n({ ...tFormStepsFactory, useScope: 'global' });

  const { storeOpenDay } = useApiListing();

  const getFormStepsLpOpenDay = (): FormStepsFactory => {
    const submit = async (formData: StoreOpenDayVariables) => {
      const { getToken } = useCaptcha();

      const rToken = await getToken('submitOpenDay');

      const formDataLocal = { ...formData, r_token: rToken };

      storeOpenDay(formDataLocal).then(() => {
        // useGTM(gtmEvent);
      });
    };

    return computed<Step[]>(() => [
      {
        component: () => LPFormOpenDay,
        formId: 'lp-open-day',
        onNextCallback: (next, state) => {
          if (!user.value) {
            dialogFactoryId.value = factoryId;
            authMeta.value.email.title = t('title.openDayLogin');
            authMeta.value.email.subtitle = t('subtitle.openDayLogin');

            dialogAuth.value = true;

            watch(
              dialogAuth,
              () => {
                if (user.value) {
                  state.onNext(next);
                }
              },
              { once: true }
            );
          } else {
            state.onNext(next);
          }
        },
      },
      {
        component: () => FormStepperCallback,
        componentProps: {
          onMountedCallbackFn: submit,
          onMountedCallbackArgs: (next: Next) => [next.formData],
          formInfoMsgPropsError: {
            qIconProps: {
              color: 'negative',
              name: 'warning',
              size: '5rem',
            },
            subtitle: { text: 'Error subtitle' },
            title: { text: 'Error title' },
          },
          formInfoMsgPropsSuccess: {
            qIconProps: {
              color: 'primary',
              name: 'success',
              size: '5rem',
            },
            subtitle: { text: t('subtitle.openDay') },
            title: { text: t('title.openDay') },
          },
        },
        formId: 'callback',
      },
    ]);
  };

  return { factoryId, getFormStepsLpOpenDay };
};

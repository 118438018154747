<template>
  <q-form :id="btnPropsSubmit.form" class="f-form lp-form-offer-terms" @submit.prevent="onSubmit">
    <q-card bordered flat>
      <q-card-section style="max-height: 60vh; overflow-y: auto">
        <q-inner-loading :showing="loading">
          <q-spinner size="5rem" color="primary" />
        </q-inner-loading>

        <div v-show="!loading && !!termsAndConditions" v-dompurify-html="termsAndConditions" />
      </q-card-section>
    </q-card>

    <q-checkbox
      v-model="formData.acceptTerms"
      class="text-body2"
      color="primary"
      :disable="loading"
      :label="t('LPOfferTerms.fields.acceptTerms.label')"
    />

    <Teleport defer :disabled="!isDialog" :to="teleportTarget">
      <q-btn v-bind="btnPropsCancel" @click="onPrev" />
      <q-btn v-bind="btnPropsSubmit" />
    </Teleport>
  </q-form>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { useApiListing } from '@/composables/api/listing';
import tFormSteps from '@/i18n/translations/components/formSteps.json';
import { useListingStore } from '@/store/modules/listing';
import type { FNextOffer, FormId } from '@/types/formStepsFactory';

const props = defineProps<{
  formId: FormId;
  isDialog?: boolean;
  next: FNextOffer;
}>();
const emit = defineEmits<{
  (e: 'next', p: typeof props.next): void;
  (e: 'prev'): void;
}>();

const teleportTarget = computed(() => `#${props.formId} .dialog-form--actions`);

const { indexOfferTerms } = useApiListing();
const { t } = useI18n(tFormSteps);

const { listing } = storeToRefs(useListingStore());
const loading = ref(false);
const termsAndConditions = ref('');
const formData = ref({ acceptTerms: props.next.formData.acceptTerms || false });

const btnPropsSubmit = computed(() => ({
  class: 'text-body2-bold border-radius-xl',
  color: 'primary',
  disable: loading.value || !formData.value.acceptTerms,
  form: `f-${props.formId}`,
  label: t('LPOfferTerms.btn.submit'),
  noCaps: true,
  padding: '0.8rem',
  textColor: 'white',
  type: 'submit',
  unelevated: true,
}));

const btnPropsCancel = computed(() => ({
  class: 'text-body2-bold border-radius-xl',
  color: 'primary',
  label: t('LPOfferTerms.btn.back'),
  noCaps: true,
  outline: true,
  padding: '0.8rem',
  unelevated: true,
}));

const getTerms = () => {
  if (!listing.value) return;

  loading.value = true;

  indexOfferTerms(listing.value.id)
    .then(response => {
      termsAndConditions.value = response.data.data;
    })
    .catch(() => {
      //
    })
    .finally(() => {
      loading.value = false;
    });
};

const onSubmit = () => {
  if (!formData.value.acceptTerms) return;

  emit('next', {
    ...props.next,
    formData: { ...props.next.formData, acceptTerms: formData.value.acceptTerms },
  });
};

const onPrev = () => {
  emit('prev');
};

onMounted(() => {
  getTerms();
});
</script>

<style lang="scss">
ul.terms-list {
  padding-inline-start: 1.375rem;
  margin-block: 0;
  margin-inline: 0;
  list-style-type: disc;
}
</style>

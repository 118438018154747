<template>
  <FInputSelect
    v-model="modelValue.customerType"
    :label="t('LPOfferAdditional.fields.customerType.label')"
    emit-value
    :error="!!errors.customerType?.[0]"
    :error-message="errors.customerType?.[0]"
    map-options
    :options="options"
    outlined
  />

  <FInputPhone
    v-model="modelValue.phoneNumber"
    :error="!!errors.phoneNumber?.[0]"
    :error-message="errors.phoneNumber?.[0]"
    :label="t('LPOfferAdditional.fields.phoneNumber.label')"
    outlined
    :rules="[requiredDefault]"
    :required="true"
  />

  <FInput
    v-model="modelValue.emailAddress"
    :error="!!errors.emailAddress?.[0]"
    :error-message="errors.emailAddress?.[0]"
    :label="t('LPOfferAdditional.fields.emailAddress.label')"
    :rules="[requiredDefault]"
    :required="true"
    outlined
  />

  <template v-if="modelValue.customerType === 'Individual'">
    <FInput
      v-model="modelValue.idNumber"
      :error="!!errors.idNumber?.[0]"
      :error-message="errors.idNumber?.[0]"
      :label="t('LPOfferAdditional.fields.idNumber.label')"
      :rules="[requiredDefault]"
      :required="true"
      outlined
    />

    <FInput
      v-model="modelValue.address"
      :error="!!errors.address?.[0]"
      :error-message="errors.address?.[0]"
      :label="t('LPOfferAdditional.fields.address.label')"
      :rules="[requiredDefault]"
      :required="true"
      outlined
    />

    <FInputFile
      v-model="modelValue.proofOfIdentityDocument"
      clearable
      :display-value="modelValue.proofOfIdentityDocument?.name || 'Select File...'"
      :error="!!errors.proofOfIdentityDocument?.[0]"
      :error-message="errors.proofOfIdentityDocument?.[0]"
      :label="t('LPOfferAdditional.fields.proofOfIdentityDocument.label')"
      :rules="[requiredDefault]"
      :required="true"
      outlined
    >
      <template #prepend>
        <q-icon color="secondary" name="attachment" />
      </template>
    </FInputFile>

    <FInputFile
      v-model="modelValue.proofOfAddress"
      clearable
      :display-value="modelValue.proofOfAddress?.name || 'Select File...'"
      :error="!!errors.proofOfAddress?.[0]"
      :error-message="errors.proofOfAddress?.[0]"
      :label="t('LPOfferAdditional.fields.proofOfAddress.label')"
      :rules="[requiredDefault]"
      :required="true"
      outlined
    >
      <template #prepend>
        <q-icon color="secondary" name="attachment" />
      </template>
    </FInputFile>
  </template>

  <template v-else-if="modelValue.customerType === 'Company'">
    <FInput
      v-model="modelValue.idNumber"
      :error="!!errors.idNumber?.[0]"
      :error-message="errors.idNumber?.[0]"
      :label="t('LPOfferAdditional.fields.companyNumber.label')"
      :rules="[requiredDefault]"
      :required="true"
      outlined
    />

    <FInputFile
      v-model="modelValue.proofOfIdentityDocument"
      clearable
      :display-value="modelValue.proofOfIdentityDocument?.name || 'Select File...'"
      :error="!!errors.proofOfIdentityDocument?.[0]"
      :error-message="errors.proofOfIdentityDocument?.[0]"
      :label="t('LPOfferAdditional.fields.companyCertificate.label')"
      :rules="[requiredDefault]"
      :required="true"
      outlined
    >
      <template #prepend>
        <q-icon color="secondary" name="attachment" />
      </template>
    </FInputFile>
  </template>

  <FInputFile
    v-model="modelValue.regCertificateWithREAA"
    clearable
    :display-value="modelValue.regCertificateWithREAA?.name || 'Select File...'"
    :error="!!errors.regCertificateWithREAA?.[0]"
    :error-message="errors.regCertificateWithREAA?.[0]"
    :label="t('LPOfferAdditional.fields.regCertificateWithREAA.label')"
    :rules="[requiredDefault]"
    :required="true"
    outlined
  >
    <template #prepend>
      <q-icon color="secondary" name="attachment" />
    </template>
  </FInputFile>

  <FInputFile
    v-model="modelValue.validAnnualPracticingLicence"
    clearable
    :display-value="modelValue.validAnnualPracticingLicence?.name || 'Select File...'"
    :error="!!errors.validAnnualPracticingLicence?.[0]"
    :error-message="errors.validAnnualPracticingLicence?.[0]"
    :label="t('LPOfferAdditional.fields.validAnnualPracticingLicence.label')"
    :rules="[requiredDefault]"
    :required="true"
    outlined
  >
    <template #prepend>
      <q-icon color="secondary" name="attachment" />
    </template>
  </FInputFile>

  <FInputFile
    v-model="modelValue.validProfessionalInsurancePolicy"
    clearable
    :display-value="modelValue.validProfessionalInsurancePolicy?.name || 'Select File...'"
    :error="!!errors.validProfessionalInsurancePolicy?.[0]"
    :error-message="errors.validProfessionalInsurancePolicy?.[0]"
    :label="t('LPOfferAdditional.fields.validProfessionalInsurancePolicy.label')"
    :rules="[requiredDefault]"
    :required="true"
    outlined
  >
    <template #prepend>
      <q-icon color="secondary" name="attachment" />
    </template>
  </FInputFile>

  <FInputFile
    v-model="modelValue.authorizationAgencyDeclarationForm"
    clearable
    :display-value="modelValue.authorizationAgencyDeclarationForm?.name || 'Select File...'"
    :error="!!errors.authorizationAgencyDeclarationForm?.[0]"
    :error-message="errors.authorizationAgencyDeclarationForm?.[0]"
    :label="t('LPOfferAdditional.fields.authorizationAgencyDeclarationForm.label')"
    :rules="[requiredDefault]"
    :required="true"
    outlined
  >
    <template #prepend>
      <q-icon color="secondary" name="attachment" />
    </template>

    <template #label="{ label }">
      <div class="q-mb-sm">
        <span class="f-input__label" v-text="label" />
        <i18n-t
          keypath="LPOfferAdditional.fields.authorizationAgencyDeclarationForm.templateLink"
          tag="span"
        >
          <template #here>
            <a
              class="text-bold"
              :href="fileTemplateLink"
              target="_blank"
              rel="noopener noreferrer"
              v-text="t('LPOfferAdditional.extra.here')"
            />
          </template>
        </i18n-t>
      </div>
    </template>
  </FInputFile>

  <FInputToggle
    v-model="modelValue.hasPriorRelationship"
    :error-message="errors.hasPriorRelationship?.[0]"
    :label="t('LPOfferAdditional.fields.hasPriorRelationship.label')"
    :rules="[requiredHasPriorRelationship]"
    :required="true"
  />

  <FInput
    v-if="modelValue.hasPriorRelationship === 'Yes'"
    v-model="modelValue.relationshipDescription"
    :error="!!errors.relationshipDescription?.[0]"
    :error-message="errors.relationshipDescription?.[0]"
    :label="t('LPOfferAdditional.fields.relationshipDescription.label')"
    :rules="[requiredDefault]"
    :required="true"
    outlined
    type="textarea"
  />

  <FInputToggle
    v-model="modelValue.hasArrearsOrRestructuredCreditFacilities"
    :error-message="errors.hasArrearsOrRestructuredCreditFacilities?.[0]"
    :label="t('LPOfferAdditional.fields.hasArrearsOrRestructuredCreditFacilities.label')"
    :rules="[requiredHasArrearsOrRestructuredCreditFacilities]"
    :required="true"
  />

  <FInputToggle
    v-model="modelValue.isDirectlyConnectedWithTheTenderer"
    :error-message="errors.isDirectlyConnectedWithTheTenderer?.[0]"
    :label="t('LPOfferAdditional.fields.isDirectlyConnectedWithTheTenderer.label')"
    :rules="[requiredIsDirectlyConnectedWithTheTenderer]"
    :required="true"
  >
    <template #prepend>
      <q-icon color="secondary" name="attachment" />
    </template>
  </FInputToggle>

  <FInput
    v-if="modelValue.isDirectlyConnectedWithTheTenderer === 'Yes'"
    v-model="modelValue.relationshipToTheTendererDescription"
    :error="!!errors.relationshipToTheTendererDescription?.[0]"
    :error-message="errors.relationshipToTheTendererDescription?.[0]"
    :label="t('LPOfferAdditional.fields.relationshipToTheTendererDescription.label')"
    :rules="[requiredDefault]"
    :required="true"
    outlined
    type="textarea"
  />
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

import FInput from '@/components/Form/input/FInput.vue';
import FInputFile from '@/components/Form/input/FInputFile.vue';
import FInputPhone from '@/components/Form/input/FInputPhone.vue';
import FInputSelect from '@/components/Form/input/FInputSelect.vue';
import FInputToggle from '@/components/Form/input/FInputToggle.vue';
import { useFormInputRules } from '@/composables/formInputRules';
import tFormSteps from '@/i18n/translations/components/formSteps.json';
import type { FNextOffer } from '@/types/formStepsFactory';

type FormDataAdditional = {
  address?: null | string;
  authorizationAgencyDeclarationForm?: File | null;
  customerType?: null | 'Company' | 'Individual';
  emailAddress?: null | string;
  idNumber?: null | string;
  isDirectlyConnectedWithTheTenderer?: null | string;
  phoneNumber?: null | string;
  proofOfIdentityDocument?: File | null;
  proofOfAddress?: File | null;
  regCertificateWithREAA?: File | null;

  relationshipToTheTendererDescription?: null | string;
  validAnnualPracticingLicence?: File | null;
  validProfessionalInsurancePolicy?: File | null;

  hasPriorRelationship?: 'Yes' | 'No' | null;
  relationshipDescription?: string | null;
  hasArrearsOrRestructuredCreditFacilities: 'Yes' | 'No' | null;
};

type FormData = Record<'formData', FNextOffer['formData']> & FormDataAdditional;

defineProps<{
  errors: Partial<Record<keyof FormData | string, string[]>>;
}>();

const { t } = useI18n(tFormSteps);
const { required } = useFormInputRules();

const modelValue = defineModel<Partial<FormData>>('modelValue', { required: true });

const options = [
  { label: t('LPOfferAdditional.fields.customerType.options.individual'), value: 'Individual' },
  { label: t('LPOfferAdditional.fields.customerType.options.company'), value: 'Company' },
];

const fileTemplateLink =
  'https://prosperty-gr-marketing.s3.eu-central-1.amazonaws.com/Altia+tender+form+-+Schedule+1.pdf';

const requiredDefault = required();

const requiredHasPriorRelationship = required(
  t('LPOfferAdditional.fields.hasPriorRelationship.rules.required')
);

const requiredHasArrearsOrRestructuredCreditFacilities = required(
  t('LPOfferAdditional.fields.hasArrearsOrRestructuredCreditFacilities.rules.required')
);

const requiredIsDirectlyConnectedWithTheTenderer = required(
  t('LPOfferAdditional.fields.isDirectlyConnectedWithTheTenderer.rules.required')
);

onMounted(() => {
  if (!modelValue.value.customerType) {
    modelValue.value.customerType = 'Individual';
  }
});
</script>
